import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Scrollto {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.scrollto = document.querySelectorAll('.scrollto');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.scrollto.length; ++i) {
        cache.scrollto[i].addEventListener("click", scrollToTarget );
      }
    };

    let scrollToTarget = function(e){
        document.querySelector('body').classList.add('scroll');
        e.preventDefault();
        let target = document.querySelector(this.getAttribute("href"));
        anime({
            targets: globalVar.scrollElement,
            scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
            duration: 800,
            easing: 'easeOutQuad',
            complete: function () {
            document.querySelector('body').classList.remove('scroll');
            }
        });
    }

    initFunc();
  };
}
export { Scrollto }