import anime from '../libs/anime'

class PopupInfo {
  constructor() {
    let cache = {};
    let scrollPos;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.popup = document.querySelector('.popup-info');
      cache.popup_btn = document.querySelector('.popup-info_btn');
    };

    let registerEvents = () => {
        window.addEventListener('scroll', scrollDetect);
        cache.popup_btn.addEventListener("click", openPopup );
    };

    let openPopup = function(){
        cache.popup_btn.removeEventListener("click", openPopup );
        cache.popup_btn.classList.add('open');
        cache.popup.style.display = "block";
        anime({
            targets: cache.popup,
            translateX: ['100%', '0%'],
            duration: 600,
            easing: 'easeOutQuad',
            complete: function() {
                cache.popup_btn.addEventListener("click", closePopup );
                cache.popup.addEventListener("click", closePopup );
            }
        });

        anime({
            targets: cache.popup.querySelector('.bg'),
            scale: [2.5,1],
            translateX: ['10%', '0%'],
            duration: 600,
            easing: 'easeOutQuad',
        });
    }

    let closePopup = function(){
        cache.popup_btn.removeEventListener("click", closePopup );
        cache.popup.removeEventListener("click", closePopup );
        cache.popup_btn.classList.remove('open');
        anime({
            targets: cache.popup.querySelector('.bg'),
            scale: [1,2.5],
            translateX: ['0%', '10%'],
            duration: 600,
            easing: 'easeOutQuad',
        });
        anime({
            targets: cache.popup,
            translateX: ['0%', '100%'],
            duration: 600,
            easing: 'easeOutQuad',
            complete: function() {
                cache.popup_btn.addEventListener("click", openPopup );
                cache.popup.style.display = "none";
            }
        });
    }

    let scrollDetect = function() {
        let up = false;
        if(scrollPos > window.pageYOffset) {
          up = true;
        }
        scrollPos = window.pageYOffset;
  
        if(scrollPos > 150 && !up && !cache.popup_btn.classList.contains('up') ) {
            cache.popup_btn.classList.add('up');
        } else if(scrollPos > 150 && up && cache.popup_btn.classList.contains('up') ) {
            cache.popup_btn.classList.remove('up');
        }
      }

    initFunc();
  };
}
export { PopupInfo }