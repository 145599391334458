import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class M07Cartes {
  constructor() {
    let scrollPos = 0;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.sectionCard = document.querySelector('.module-07-carte_image.layout-anime');
        cache.cards = cache.sectionCard.querySelectorAll('.card');
        cache.tag = cache.sectionCard.querySelectorAll('.scroll-nav_item');
    };

    let registerEvents = () => {
        window.addEventListener('scroll', scrollCards);
        for (let i = 0; i < cache.tag.length; i++) {
            cache.tag[i].addEventListener('click', scrollToCard);
        }
    };



    let scrollCards = function(event) {
        let up = false;
        if(scrollPos > window.pageYOffset) {
          up = true;
        }
        scrollPos = window.pageYOffset;
    
    
        if(((scrollPos + window.innerHeight) > (cache.sectionCard.offsetTop + window.innerHeight)) && ((scrollPos + window.innerHeight) < (cache.sectionCard.offsetTop + window.innerHeight + cache.sectionCard.offsetHeight/3))) {
          let percent = (scrollPos - cache.sectionCard.offsetTop)/(cache.sectionCard.offsetHeight/3);
          if(percent > 0.5) {
            cache.cards[0].style.zIndex = 1;
            cache.cards[1].style.zIndex = 3;
            cache.cards[2].style.zIndex = 2;
          } else {
            cache.cards[0].style.zIndex = 3;
            cache.cards[1].style.zIndex = 2;
            cache.cards[2].style.zIndex = 1;
          }
          let card1 = anime({
            targets: cache.cards[0],
            duration: 400,
            translateY: ['0%','-105%'],
            easing: 'easeInQuad',
            autoplay: false,
            complete: function() {
                if(!up) {
                    cache.tag[0].classList.remove('active');
                    cache.tag[1].classList.add('active');
                } else {
                    cache.tag[1].classList.remove('active');
                    cache.tag[0].classList.add('active');
                    anime({
                        targets: cache.tag[0].closest('.scroll-nav'),
                        scrollLeft: cache.tag[0].offsetLeft,
                        duration: 300,
                        easing: 'easeOutQuad',
                    });
                }
                
                let card1_2 = anime({
                    targets: cache.cards[0],
                    duration: 400,
                    translateY: ['-105%', '6%'],
                    easing: 'easeOutQuad',
                    autoplay: false,
                });
                card1_2.seek(((percent*2) - 1) * card1_2.duration);
                if(cache.tag[2].classList.contains('active')) {
                    cache.tag[2].classList.remove('active');
                }
            }
          });
    
          let card2 = anime({
            targets: cache.cards[1],
            duration: 400,
            delay: 100,
            translateY: ['3%', '0%'],
            easing: 'easeInOutQuad',
            autoplay: false
          });
    
          let card3 = anime({
            targets: cache.cards[2],
            duration: 400,
            delay: 200,
            translateY: ['6%', '3%'],
            easing: 'easeInOutQuad',
            autoplay: false
          });
    
          card1.seek((percent*2) * card1.duration);
          card2.seek((percent) * card2.duration);
          card3.seek((percent) * card3.duration);
        }
    
        if(((scrollPos + window.innerHeight) > (cache.sectionCard.offsetTop + window.innerHeight + cache.sectionCard.offsetHeight/3)) && ((scrollPos + window.innerHeight) < (cache.sectionCard.offsetTop + window.innerHeight + cache.sectionCard.offsetHeight))) {
          let percent = (scrollPos - cache.sectionCard.offsetTop - cache.sectionCard.offsetHeight/3)/(cache.sectionCard.offsetHeight/3);
          if(percent < 0.5) {
            cache.cards[1].style.zIndex = 3;
            cache.cards[2].style.zIndex = 2;
            cache.cards[0].style.zIndex = 1;
          } else {
            cache.cards[1].style.zIndex = 1;
            cache.cards[2].style.zIndex = 3;
            cache.cards[0].style.zIndex = 2;
          }
    
          let card1 = anime({
            targets: cache.cards[1],
            duration: 400,
            translateY: ['0%','-105%'],
            easing: 'easeInQuad',
            autoplay: false,
            complete: function() {
                if(!up) {
                    cache.tag[1].classList.remove('active');
                    cache.tag[2].classList.add('active');
                    anime({
                        targets: cache.tag[2].closest('.scroll-nav'),
                        scrollLeft: cache.tag[2].offsetLeft,
                        duration: 300,
                        easing: 'easeOutQuad',
                    });
                } else {
                    cache.tag[2].classList.remove('active');
                    cache.tag[1].classList.add('active');
                }
                let card1_2 = anime({
                    targets: cache.cards[1],
                    duration: 400,
                    translateY: ['-105%', '6%'],
                    easing: 'easeOutQuad',
                    autoplay: false,
                });
                card1_2.seek(((percent*2) - 1) * card1_2.duration);
                if(cache.tag[0].classList.contains('active')) {
                    cache.tag[0].classList.remove('active');
                }
            }
          });
    
          let card2 = anime({
            targets: cache.cards[2],
            duration: 400,
            delay: 100,
            translateY: ['3%', '0%'],
            easing: 'easeInOutQuad',
            autoplay: false
          });
    
          let card3 = anime({
            targets: cache.cards[0],
            duration: 400,
            delay: 200,
            translateY: ['6%', '3%'],
            easing: 'easeInOutQuad',
            autoplay: false
          });
    
          card1.seek((percent*2) * card1.duration);
          card2.seek((percent) * card2.duration);
          card3.seek((percent) * card3.duration);
        }
    
    }


    let scrollToCard = function() {
        let targetIndex = parseInt(this.dataset.target);
        let secHeight = window.innerHeight * 1.5;
        let pos = cache.sectionCard.offsetTop + (targetIndex * secHeight);
        anime({
            targets: globalVar.scrollElement,
            scrollTop: pos,
            duration: 600,
            easing: 'easeOutQuad',
        });
    }

    initFunc();
  };
}
export { M07Cartes }