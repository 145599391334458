class Form {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.label = document.querySelectorAll('form label');
      cache.form = document.querySelectorAll('form');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.label.length; ++i) {
        if(cache.label[i].querySelector('.placeholder') && cache.label[i].querySelector('input, textarea')) {
            cache.label[i].querySelector('input, textarea').addEventListener("focus", addActive );
            cache.label[i].querySelector('input, textarea').addEventListener("blur", removeActive );
        }
      }
      for (let i = 0; i < cache.form.length; ++i) {
        if(cache.form[i].querySelector('.placeholder') && cache.form[i].querySelector('input, textarea')) {
            cache.form[i].addEventListener('wpcf7mailsent', resetForm );
        }
      }
    };

    let addActive = function(){
        const el = this.closest('label');
        if(!el.classList.contains('active')) {
            el.classList.add('active');
        }
    }

    let removeActive = function(){
        const el = this.closest('label');
        const field = this;
        if(el.classList.contains('active') && ((field.value == null || field.value == ""))) {
            el.classList.remove('active');
        }
    }

    let resetForm = function(){
        console.log('wrg')
        const labels = this.querySelectorAll('label');
        for (let i = 0; i < labels.length; i++) {
            if(labels[i].classList.contains('active')) {
                labels[i].classList.remove('active');
            }
        }
    }

    initFunc();
  };
}
export { Form }