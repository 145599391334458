import anime from '../libs/anime'
import globalVar from '../utils/globalVar'


class M03SliderComplexe {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.slider = document.querySelectorAll('.module-03-slider.layout-complexe');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.slider.length; ++i) {
        const arrowL = cache.slider[i].querySelector('.arrow_left');
        const arrowR = cache.slider[i].querySelector('.arrow_right');
        arrowL.addEventListener("click", slideLeft );
        arrowR.addEventListener("click", slideRight );
      }
    };

    let slideLeft = function(){
        const arrow = this;
        const arrowR = arrow.closest('.layout-complexe').querySelector('.arrow_right')
        const slider = arrow.closest('.layout-complexe').querySelector('.slider_container');
        if(arrowR.classList.contains('disabled')) {
            arrowR.classList.remove('disabled');
        }
        let dist = window.innerWidth;
        let time = 1000;
        if(globalVar.mobile.matches) {
            time = 400;
        }

        anime({
            targets: slider,
            scrollLeft: (slider.scrollLeft - dist),
            duration: time,
            easing: 'easeOutQuad',
            complete: function() {
                if((slider.scrollLeft - dist) < window.innerWidth) {
                    arrow.classList.add('disabled');
                }
            }
        });
    }

    let slideRight = function(){
        const arrow = this;
        const arrowL = arrow.closest('.layout-complexe').querySelector('.arrow_left')
        const slider = arrow.closest('.layout-complexe').querySelector('.slider_container');
        if(arrowL.classList.contains('disabled')) {
            arrowL.classList.remove('disabled');
        }
        let dist = window.innerWidth;
        let time = 1000;
        if(globalVar.mobile.matches) {
            time = 400;
        }

        anime({
            targets: slider,
            scrollLeft: (slider.scrollLeft + dist),
            duration: time,
            easing: 'easeOutQuad',
            complete: function() {
                if((slider.scrollWidth - window.innerWidth) < (slider.scrollLeft+dist) ) {
                    arrow.classList.add('disabled');
                }
            }
        });
    }

    initFunc();
  };
}
export { M03SliderComplexe }