import { Nav } from './components/nav'
import { Credit } from './components/credit'
import { Form } from './components/form'
import { GoogleMap } from './components/googleMap'
import { Scrollto } from './components/scrollto'
import { BackToTop } from './components/backToTop'
import { PopupInfo } from './components/popupInfo'

import { M03SliderSimple } from './modules/module-03-slider_simple'
import { M03SliderComplexe } from './modules/module-03-slider_complexe'
import { M05Dropdown } from './modules/module-05-dropdown'
import { M07Cartes } from './modules/module-07-carte_image'

import { CptParcoursLocator } from './templates/cpt-parcours'
import { ArchiveCommerces } from './templates/archive-commerce'
import { ArchiveActualites } from './templates/archive-actualites'



class App {
  constructor() {
    const nav = new Nav;

    if(document.querySelector('.credit')) {
      const credit = new Credit;
    }
    if(document.querySelector('form label .placeholder')) {
      const form = new Form;
    }
    if(document.querySelector('.google-map')) {
      const googleMap = new GoogleMap;
    }
    if(document.querySelector('.scrollto')) {
      const scrollto = new Scrollto;
    }
    if(document.querySelector('.back-to-top')) {
      const backToTop = new BackToTop;
    }
    if(document.querySelector('.popup-info')) {
      const popupInfo = new PopupInfo;
    }
    

    if(document.querySelector('.module-03-slider.layout-simple')) {
      const m03SliderSimple = new M03SliderSimple;
    }
    if(document.querySelector('.module-03-slider.layout-complexe')) {
      const m03SliderComplexe = new M03SliderComplexe;
    }
    if(document.querySelector('.module-05-dropdown')) {
      const m05Dropdown = new M05Dropdown;
    }
    if(document.querySelector('.module-07-carte_image')) {
      const m07Cartes = new M07Cartes;
    }


    if(document.querySelector('.cpt-parcours .map')) {
      const cptParcoursLocator = new CptParcoursLocator;
    }
    if(document.querySelector('.template-archive-commerces')) {
      const archiveCommerces = new ArchiveCommerces;
    }
    if(document.querySelector('.template-archive-actualites')) {
      const archiveActualites = new ArchiveActualites;
    }
  }
}

window.onload = function() {
  let app = new App();
};
