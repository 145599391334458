import anime from '../libs/anime'

class CptParcoursLocator {
    constructor() {
    let cache = {};
    let markers = [];
    let initFunc = () => {
        initCache();
        registerEvents();
    }
  
    let initCache = () => {
        cache.mapsSection = document.querySelectorAll('.quartier');
        if(document.querySelector('.quartier_wrap .tag')) {
            cache.tab = document.querySelectorAll('.quartier_wrap .tag');
        }
    };
      
    let registerEvents = () => {  
      for (let i = 0; i < cache.mapsSection.length; i++) {
        initMap(cache.mapsSection[i]);
      }
      if(document.querySelector('.quartier_wrap .tag')) {
        for (let i = 0; i < cache.tab.length; i++) {
            cache.tab[i].addEventListener('click', changeTab);
        }
      }
    };

    let initMap = function(quartier){
    let map;
    let dealers = quartier.querySelectorAll('.card_commerce');
    let center = {
        el: document.querySelector('#marker0'),
        lat: 45.491307,
        lng: -73.565012,
    }
    if(quartier.classList.contains('petite-bourgogne')) {
      center = {
        el: document.querySelector('#marker0'),
        lat: 45.483535,
        lng: -73.575271,
      }
    }
    if(quartier.classList.contains('saint-henri')) {
      center = {
        el: document.querySelector('#marker0'),
        lat: 45.47775,
        lng: -73.583982,
      }
    }
        
        const styleMap =[{
            "stylers": [{ 
               "hue": "#E9EDF2"
             }, {
               "saturation": -30
             }, {
               "lightness": 20
             }]
        }];
        
        map = new google.maps.Map(quartier.querySelector(".map"), {
          center: { lat: center.lat, lng: center.lng },
          zoom: 15,
          styles: styleMap,
        });
  
        dealers.forEach(function (dealer, index) {
  
          dealer.addEventListener('click', function() {
            if(quartier.querySelector(".active")) {
              quartier.getElementsByClassName('active')[0].classList.remove('active');
            }
            this.classList.add('active');
            let bounds = new google.maps.LatLngBounds();
            let latlng = new google.maps.LatLng(this.dataset.lat,this.dataset.lng);
            bounds.extend(latlng);
            google.maps.event.addListener(map, 'zoom_changed', function() {
            let zoomChangeBoundsListener = 
                    google.maps.event.addListener(map, 'bounds_changed', function(event) {
                        if (map.getZoom() > 16 && map.initialZoom == true) {
                            // Change max/min zoom here
                            map.setZoom(16);
                            map.initialZoom = false;
                        }
                    google.maps.event.removeListener(zoomChangeBoundsListener);
                });
            });
            map.initialZoom = true;
            map.fitBounds(bounds);
            map.fitBounds(bounds);
          })
  
  
          let marker = new google.maps.Marker({
            position: { lat: parseFloat(dealer.dataset.lat), lng: parseFloat(dealer.dataset.lng) },
            map,
            id: dealer.id,
            title: dealer.querySelector('.nom').txt,
            });
  
            marker.addListener('click', function() {
                let el = document.getElementById(this.id);
                let quartier = el.closest('.quartier');
                if(quartier.getElementsByClassName('active').length > 0) {
                    quartier.getElementsByClassName('active')[0].classList.remove('active');
                }

                anime({
                    targets: quartier.querySelector('.list'),
                    scrollTop: (el.offsetTop-quartier.querySelector('.list').offsetTop),
                    duration:  800,
                    easing: 'easeOutQuad',
                });

                el.classList.add('active');
                let bounds = new google.maps.LatLngBounds();
                let latlng = new google.maps.LatLng(el.dataset.lat,el.dataset.lng);
                bounds.extend(latlng);
                google.maps.event.addListener(map, 'zoom_changed', function() {
                let zoomChangeBoundsListener = 
                        google.maps.event.addListener(map, 'bounds_changed', function(event) {
                            if (map.getZoom() > 16 && map.initialZoom == true) {
                                // Change max/min zoom here
                                map.setZoom(16);
                                map.initialZoom = false;
                            }
                        google.maps.event.removeListener(zoomChangeBoundsListener);
                    });
                });
                map.initialZoom = true;
                map.fitBounds(bounds);
                map.fitBounds(bounds);
                });
      
            markers.push(marker);
        });
  
      }
  

      let changeTab = function(){
        let currentTab = document.querySelector('.quartier_wrap .tag.active');
        let currentQuar = document.querySelector('.'+currentTab.dataset.quartier);
        let targetTab = this;
        let targetQuar = document.querySelector('.'+targetTab.dataset.quartier);

        currentTab.classList.remove('active');
        targetTab.classList.add('active');

        anime({
            targets: currentQuar,
            translateX: ['0%','-100%'],
            duration: 400,
            opacity: [1,0],
            easing: 'easeOutQuad',
            complete: function() {
                currentQuar.style.display = "none";
                targetQuar.style.display = "flex";
                anime({
                    targets: targetQuar,
                    translateX: ['100%','0%'],
                    opacity: [0,1],
                    duration: 400,
                    easing: 'easeOutQuad',
                });
            }
        });
      }
   

      initFunc();
    }
  }
  export { CptParcoursLocator }