import anime from '../libs/anime'

class M05Dropdown {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.dropdowns = document.querySelectorAll('.module-05-dropdown .dropdown');
      cache.dropdownsOpen = document.querySelectorAll('.module-05-dropdown .dropdown_open');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.dropdowns.length; ++i) {
        cache.dropdowns[i].addEventListener("click", openDrop );
      }

      for (let i = 0; i < cache.dropdownsOpen.length; i++) {
        cache.dropdownsOpen[i].removeEventListener("click", openDrop );
        cache.dropdownsOpen[i].addEventListener("click", closeDrop);
        let content = cache.dropdownsOpen[i].querySelector('.content');
        content.style.width = "100%";
        content.style.height = (content.querySelector('.txt').offsetHeight + 20) + "px";
      }
    };

    let openDrop = function(){
        let drop = this;
        drop.removeEventListener("click", openDrop );
        drop.addEventListener("click", closeDrop);
        let content = drop.querySelector('.content');
        drop.classList.add('dropdown_open');
        drop.querySelector('.titre').classList.add('anime');
        anime({
            targets: content,
            width: '100%',
            duration: 300,
            easing: 'easeOutQuad',
            complete: function() {
                drop.querySelector('.titre').classList.remove('anime');
                let height = (content.querySelector('.txt').offsetHeight + 20) + "px";
                anime({
                    targets: content,
                    height: height,
                    duration: 400,
                    easing: 'easeOutQuad'
                  });
            }
          });
    }

    let closeDrop = function(){
        let drop = this;
        drop.addEventListener("click", openDrop );
        drop.removeEventListener("click", closeDrop);
        let content = drop.querySelector('.content');
        anime({
            targets: content,
            height: 0,
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                drop.classList.remove('dropdown_open');
                drop.querySelector('.titre').classList.add('anime');
                anime({
                    targets: content,
                    width: "0%",
                    duration: 300,
                    easing: 'easeOutQuad',
                    complete: function() {
                      drop.querySelector('.titre').classList.remove('anime');
                    }
                  });
            }
          });
    }

    initFunc();
  };
}
export { M05Dropdown }