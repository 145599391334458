import anime from '../libs/anime'

class ArchiveCommerces {
    constructor() {
    let cache = {};

    const ppp = 12;
    let iPosts = 0;
    let str;
    let filterQuartier;
    let filterCat;
    let filterSub;

    let initFunc = () => {
        initCache();
        registerEvents();
    }
  
    let initCache = () => {
        cache.filterDropdowns = document.querySelectorAll('.filter_container .filter_label');
        cache.cat = document.querySelectorAll('.filter_container .dropdown-container .tag');
        cache.reset = document.querySelector('.reset');
        cache.cardContainer = document.querySelector('.card_container');
        cache.loadMore = document.querySelector('.btn.load-more');
    };
      
    let registerEvents = () => {  
        for (let i = 0; i < cache.filterDropdowns.length; i++) {
            cache.filterDropdowns[i].addEventListener('click', toggleFilterDropdowns);

            let parent = cache.filterDropdowns[i].closest('.dropdown_filter');
            parent.style.width = cache.filterDropdowns[i].offsetWidth+"px";
        }
        for (let i = 0; i < cache.cat.length; i++) {
            cache.cat[i].addEventListener('click', initFilter);
        }
        cache.reset.addEventListener('click', resetFilter);
        cache.loadMore.addEventListener('click', loadMore);
        

        // LOAD MORE BTN CHECK
        str = '&iPosts=' + (iPosts + ppp) + '&ppp=' + ppp;
        if(filterQuartier) {
        str += '&filterQuartier=' + filterQuartier;
        }
        if(filterCat) {
        str += '&filterCat=' + filterCat;
        }
        if(filterSub) {
        str += '&filterSub=' + filterSub;
        }
        str += '&action=filter_commerce_ajax';
        jQuery.ajax({
            type: "GET",
            dataType: "html",
            url: ajaxurl,
            data: str,
            success: function(dataLM){
                dataLM = jQuery(dataLM);
                if(dataLM.length){
                    if(cache.loadMore.classList.contains('hidden')) {
                        cache.loadMore.classList.remove('hidden');
                        cache.loadMore.parentNode.style.display = "flex";
                        anime({
                            targets: cache.loadMore.parentNode,
                            opacity: [0,1],
                            translateY: ['-5%', '0%'],
                            easing: 'easeInQuad',
                            duration: 300,
                        });
                    }
                } else {
                    if(!cache.loadMore.classList.contains('hidden')) {
                        cache.loadMore.classList.add('hidden');
                        anime({
                            targets: cache.loadMore.parentNode,
                            opacity: [0,1],
                            translateY: ['-5%', '0%'],
                            easing: 'easeInQuad',
                            duration: 300,
                            complete:function() {
                                cache.loadMore.parentNode.style.display = "none"; 
                            }
                        });
                    }
                }
            }
        });
    };


    let toggleFilterDropdowns = function(){
        const el = this;
        const parent = el.closest('.dropdown_filter');
        const drop = parent.querySelector('.dropdown-container');
        
        if(!parent.classList.contains('open')) {
            if(document.querySelector('.filter_container .dropdown_filter.open')) {
                closeDrop(document.querySelector('.filter_container .dropdown_filter.open'));
            }

            el.removeEventListener('click', toggleFilterDropdowns);
            parent.classList.add('open');
            drop.style.display = "block";

            anime({
                targets: drop,
                height: [0,200],
                duration: 300,
                easing: 'easeOutQuad',
                complete: function() {
                    el.addEventListener('click', toggleFilterDropdowns);  
                }
              });

        } else {
            closeDrop(parent);
        }
    }

    let closeDrop = (parent) => {  
        const el = parent.querySelector('.filter_label');
        const drop = parent.querySelector('.dropdown-container');
        el.removeEventListener('click', toggleFilterDropdowns);  
        parent.classList.remove('open');

        anime({
            targets: drop,
            height: [200,0],
            duration: 300,
            easing: 'easeOutQuad',
            complete: function() {
                drop.style.display = "none";
                el.addEventListener('click', toggleFilterDropdowns);  
            }
        });
    };

    let initFilter = function(){
        iPosts = 0;
        const el = this;
        const parent = el.closest('.dropdown_filter');
        const title = parent.querySelector('.filter_title');

        let cat = el.dataset.slug;
        closeDrop(parent);
        if(!el.classList.contains('active')) {
            if(parent.querySelector('.dropdown-container .active')) {
                parent.querySelector('.dropdown-container .active').classList.remove('active');
            }
            el.classList.add('active');
            title.textContent = el.textContent;

            if(parent.classList.contains('filter_quartier')) {
                filterQuartier = el.dataset.slug;
            }
            if(parent.classList.contains('filter_cat')) {
                filterCat = el.dataset.slug;
            }
            if(parent.classList.contains('filter_sub-cat')) {
                filterSub = el.dataset.slug;
            }

        } else {
            el.classList.remove('active');
            title.textContent = title.dataset.default;
            if(parent.classList.contains('filter_quartier')) {
                filterQuartier = "";
            }
            if(parent.classList.contains('filter_cat')) {
                filterCat = "";
                console.log(filterCat)
            }
            if(parent.classList.contains('filter_sub-cat')) {
                filterSub = "";
            }
        }

        if(parent.classList.contains('filter_cat')) {
            
            let subCatDrop = document.querySelector('.filter_sub-cat');

            if(subCatDrop.querySelector('.dropdown-container .tag.'+cat)) {
                subCatDrop.classList.remove('disabled');
                filterSub="";

                let sub = subCatDrop.querySelectorAll('.dropdown-container .tag');
                subCatDrop.querySelector('.filter_title').textContent = subCatDrop.querySelector('.filter_title').dataset.default;

                let nbTag = 0;
                for (let i = 0; i < sub.length; i++) {
                    const tag = sub[i];
                    if(tag.classList.contains(cat)) {
                        tag.style.display = "block";
                        tag.classList.remove('active');
                        nbTag++;
                    } else {
                        tag.style.display = "none";
                        tag.classList.remove('active');
                    }
                }
            } else if(!subCatDrop.querySelector('.dropdown-container .tag.'+cat) && !subCatDrop.classList.contains('disabled')) {
                subCatDrop.classList.add('disabled');
                subCatDrop.querySelector('.filter_title').textContent = subCatDrop.querySelector('.filter_title').dataset.default;
            }
        }

        ajaxFilter();
    }

    let resetFilter = function(){
        iPosts = 0;
        const filters = document.querySelectorAll('.filter_container .dropdown_filter');

        for (let i = 0; i < filters.length; i++) {
            parent = filters[i];
            if(parent.classList.contains('open')) {
                closeDrop(parent);
            }
            if(parent.querySelector('.dropdown-container .active')) {
                parent.querySelector('.dropdown-container .active').classList.remove('active');
            }
            const title = parent.querySelector('.filter_title');
            title.textContent = title.dataset.default;
        }

        document.querySelector('.filter_sub-cat').classList.add('disabled');

        filterQuartier="";
        filterCat="";
        filterSub="";

        ajaxFilter();
    }

    let ajaxFilter = function(){
        str = '&iPosts=' + iPosts + '&ppp=' + ppp;
        if(filterQuartier) {
        str += '&filterQuartier=' + filterQuartier;
        }
        if(filterCat) {
        str += '&filterCat=' + filterCat;
        }
        if(filterSub) {
          str += '&filterSub=' + filterSub;
        }
        str += '&action=filter_commerce_ajax';

        const filters = document.querySelectorAll('.filter_container .dropdown_filter');
        for (let i = 0; i < filters.length; i++) {
            filters[i].classList.add('disabled');
        }
        
        jQuery.ajax({
            type: "GET",
            dataType: "html",
            url: ajaxurl,
            data: str,
            success: function(data){
                data = jQuery(data);
                let err = document.querySelector('.error_message');
                if(data.length){
                    if(err.classList.contains('visible')) {
                        err.classList.remove('visible');
                        anime({
                            targets: err,
                            opacity: [,],
                            translateY: ['-5%', '0%'],
                            easing: 'easeInQuad',
                            delay: (ii * 50),
                            duration: 300,
                            complete: function() {
                                err.style.display = "none";

                                for (let ii = 0; ii < data.length; ii++) {
                                    const el = data[ii];
                                    el.style.opacity = 0;
                                    cache.cardContainer.prepend(el);
                                    anime({
                                        targets: el,
                                        opacity: [0,1],
                                        translateY: ['-5%', '0%'],
                                        easing: 'easeInQuad',
                                        delay: (ii * 50),
                                        duration: 300,
                                    });
                                }

                                for (let i = 0; i < filters.length-1; i++) {
                                    filters[i].classList.remove('disabled');
                                }
                                if(filterCat) {
                                    filters[2].classList.remove('disabled');
                                }
                            }
                        });

                    } else {
                        const cards = cache.cardContainer.querySelectorAll('.card:not(.card_ghost)');
                        for (let i = 0; i < cards.length; i++) {
                            const card = cards[i];
                            anime({
                                targets: card,
                                opacity: [1,0],
                                translateY: ['0%', '5%'],
                                easing: 'easeInOutQuad',
                                delay: (i * 50),
                                duration: 300,
                                complete:function() {
                                    if(i == (cards.length-1)) {
                                        for (let ii = 0; ii < cards.length; ii++) {
                                            cards[ii].parentNode.removeChild(cards[ii]);
                                        }

                                        for (let ii = 0; ii < data.length; ii++) {
                                            const el = data[ii];
                                            el.style.opacity = 0;
                                            cache.cardContainer.prepend(el);
                                            anime({
                                                targets: el,
                                                opacity: [0,1],
                                                translateY: ['-5%', '0%'],
                                                easing: 'easeInQuad',
                                                delay: (ii * 50),
                                                duration: 300,
                                            });
                                        }

                                        for (let i = 0; i < filters.length-1; i++) {
                                            filters[i].classList.remove('disabled');
                                        }
                                        if(filterCat) {
                                            filters[2].classList.remove('disabled');
                                        }
                                    }
                                }
                            });
                        }
                    }

                } else {

                    const cards = cache.cardContainer.querySelectorAll('.card:not(.card_ghost)');
                    for (let i = 0; i < cards.length; i++) {
                        const card = cards[i];
                        anime({
                            targets: card,
                            opacity: [1,0],
                            translateY: ['0%', '5%'],
                            easing: 'easeInOutQuad',
                            delay: (i * 50),
                            duration: 300,
                            complete:function() {
                                if(i == (cards.length-1)) {
                                    for (let ii = 0; ii < cards.length; ii++) {
                                        cards[ii].parentNode.removeChild(cards[ii]);
                                    }
                                    err.style.display = "block";
                                    err.classList.add('visible');
                                    anime({
                                        targets: err,
                                        opacity: [0,1],
                                        translateY: ['-5%', '0%'],
                                        easing: 'easeInQuad',
                                        duration: 300,
                                        complete: function() {
                                            for (let i = 0; i < filters.length-1; i++) {
                                                filters[i].classList.remove('disabled');
                                            }
                                            if(filterCat) {
                                                filters[2].classList.remove('disabled');
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }

                // LOAD MORE BTN CHECK
                str = '&iPosts=' + (iPosts + ppp) + '&ppp=' + ppp;
                if(filterQuartier) {
                str += '&filterQuartier=' + filterQuartier;
                }
                if(filterCat) {
                str += '&filterCat=' + filterCat;
                }
                if(filterSub) {
                str += '&filterSub=' + filterSub;
                }
                str += '&action=filter_commerce_ajax';
                jQuery.ajax({
                    type: "GET",
                    dataType: "html",
                    url: ajaxurl,
                    data: str,
                    success: function(dataLM){
                        dataLM = jQuery(dataLM);
                        if(dataLM.length){
                            if(cache.loadMore.classList.contains('hidden')) {
                                cache.loadMore.classList.remove('hidden');
                                cache.loadMore.parentNode.style.display = "flex";
                                anime({
                                    targets: cache.loadMore.parentNode,
                                    opacity: [0,1],
                                    translateY: ['-5%', '0%'],
                                    easing: 'easeInQuad',
                                    duration: 300,
                                });
                            }
                        } else {
                            if(!cache.loadMore.classList.contains('hidden')) {
                                cache.loadMore.classList.add('hidden');
                                anime({
                                    targets: cache.loadMore.parentNode,
                                    opacity: [0,1],
                                    translateY: ['-5%', '0%'],
                                    easing: 'easeInQuad',
                                    duration: 300,
                                    complete:function() {
                                        cache.loadMore.parentNode.style.display = "none"; 
                                    }
                                });
                            }
                        }
                    }
                });
            }
        });
    }  

    let loadMore = function(){
        iPosts += ppp;

        str = '&iPosts=' + iPosts + '&ppp=' + ppp;
        if(filterQuartier) {
        str += '&filterQuartier=' + filterQuartier;
        }
        if(filterCat) {
        str += '&filterCat=' + filterCat;
        }
        if(filterSub) {
          str += '&filterSub=' + filterSub;
        }
        str += '&action=filter_commerce_ajax';

        const filters = document.querySelectorAll('.filter_container .dropdown_filter');
        for (let i = 0; i < filters.length; i++) {
            filters[i].classList.add('disabled');
        }
        
        jQuery.ajax({
            type: "GET",
            dataType: "html",
            url: ajaxurl,
            data: str,
            success: function(data){
                data = jQuery(data);
                if(data.length){
                    const cards = cache.cardContainer.querySelectorAll('.card:not(.card_ghost)');
                    for (let ii = 0; ii < data.length; ii++) {
                        const el = data[ii];
                        el.style.opacity = 0;
                        cards[cards.length-1].after(el);
                        anime({
                            targets: el,
                            opacity: [0,1],
                            translateY: ['-5%', '0%'],
                            easing: 'easeInQuad',
                            delay: (ii * 50),
                            duration: 300,
                        });
                    }

                    for (let i = 0; i < filters.length-1; i++) {
                        filters[i].classList.remove('disabled');
                    }
                    if(filterCat) {
                        filters[2].classList.remove('disabled');
                    }
                } 

                // LOAD MORE BTN CHECK
                str = '&iPosts=' + (iPosts + ppp) + '&ppp=' + ppp;
                if(filterQuartier) {
                str += '&filterQuartier=' + filterQuartier;
                }
                if(filterCat) {
                str += '&filterCat=' + filterCat;
                }
                if(filterSub) {
                str += '&filterSub=' + filterSub;
                }
                str += '&action=filter_commerce_ajax';
                jQuery.ajax({
                    type: "GET",
                    dataType: "html",
                    url: ajaxurl,
                    data: str,
                    success: function(dataLM){
                        dataLM = jQuery(dataLM);
                        if(dataLM.length){
                            if(cache.loadMore.classList.contains('hidden')) {
                                cache.loadMore.classList.remove('hidden');
                                cache.loadMore.parentNode.style.display = "flex";
                                anime({
                                    targets: cache.loadMore.parentNode,
                                    opacity: [0,1],
                                    translateY: ['-5%', '0%'],
                                    easing: 'easeInQuad',
                                    duration: 300,
                                });
                            }
                        } else {
                            if(!cache.loadMore.classList.contains('hidden')) {
                                cache.loadMore.classList.add('hidden');
                                anime({
                                    targets: cache.loadMore.parentNode,
                                    opacity: [0,1],
                                    translateY: ['-5%', '0%'],
                                    easing: 'easeInQuad',
                                    duration: 300,
                                    complete:function() {
                                        cache.loadMore.parentNode.style.display = "none"; 
                                    }
                                });
                            }
                        }
                    }
                });
            }
        });
    }
   

      initFunc();
    }
  }
  export { ArchiveCommerces }