class GoogleMap {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.maps = document.querySelectorAll('.google-map');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.maps.length; ++i) {
        initMap( cache.maps[i] );
      }
    };

    let initMap = function(el) {
      const styleMap =[{
        "stylers": [{ 
           "hue": "#E9EDF2"
         }, {
           "saturation": -30
         }, {
           "lightness": 20
         }]
      }];

      let marker = el.querySelector('.marker');

      let mapArgs = {
        zoom        : parseInt(el.dataset.zoom) || 16,
        mapTypeId   : google.maps.MapTypeId.ROADMAP,
        styles      : styleMap,
        disableDefaultUI: true,
      };
      let map = new google.maps.Map( el, mapArgs );

      map.markers = [];
      initMarker( marker, map );
      centerMap( map );
      
      return map;
    }


    function initMarker( marker, map ) {

      // Get position from marker.
      let lat = marker.dataset.lat;
      let lng = marker.dataset.lng;
      let latLng = {
          lat: parseFloat( lat ),
          lng: parseFloat( lng )
      };

      // Create marker instance.
      let pin = new google.maps.Marker({
          position : latLng,
          map: map,
          icon: {
            fillColor: '#00FF00',
        },
      });

      // const pinViewBackground = new google.maps.marker.PinView({
      //   background: '#FBBC04',
      // });
      // const pin = new google.maps.marker.AdvancedMarkerView({
      //     map,
      //     position : latLng,
      //     content: pinViewBackground.element,
      // });

      // Append to reference for later use.
      map.markers.push( pin );

      // If marker contains HTML, add it to an infoWindow.
      if( marker.html ){

          // Create info window.
          let infowindow = new google.maps.InfoWindow({
              content: marker.html()
          });

          // Show info window when marker is clicked.
          google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
          });
      }
  }

  function centerMap( map ) {
    
      let bounds = new google.maps.LatLngBounds();
      map.markers.forEach(function( marker ){
      
          bounds.extend({
              lat: marker.position.lat(),
              lng: marker.position.lng()
          });
      });

      // Case: Single marker.
      if( map.markers.length == 1 ){
          map.setCenter( bounds.getCenter() );

      // Case: Multiple markers.
      } else{
          map.fitBounds( bounds );
      }
  }


    initFunc();
  };
}
export { GoogleMap }