import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class BackToTop {
  constructor() {
    let cache = {};
    let scrollPos;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.btn = document.querySelector('.back-to-top');
    };

    let registerEvents = () => {
      window.addEventListener('scroll', scrollDetect);
      cache.btn.addEventListener("click", scrollToTop );
    };

    let scrollToTop = function(){
        document.querySelector('body').classList.add('scroll');
        anime({
            targets: globalVar.scrollElement,
            scrollTop: 0,
            duration: 800,
            easing: 'easeOutQuad',
            complete: function () {
              document.querySelector('body').classList.remove('scroll');
            }
        });
    }

    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

      if(scrollPos > (window.innerHeight*1.5) && !up && !cache.btn.classList.contains('visible') ) {
        cache.btn.classList.add('visible');
      } 
      if(scrollPos < (window.innerHeight*1.5) && up && cache.btn.classList.contains('visible') ) {
        cache.btn.classList.remove('visible');
      } 
    }

    initFunc();
  };
}
export { BackToTop }