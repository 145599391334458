import anime from '../libs/anime'

class Credit {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.credits = document.querySelectorAll('.credit');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.credits.length; ++i) {
        cache.credits[i].addEventListener("mouseenter", openCred );
      }
    };

    let openCred = function(){
        this.removeEventListener("mouseenter", openCred );
        this.addEventListener("mouseleave", closeCred );

        let inner = this.querySelector('.inner');
        let width = inner.scrollWidth;
        anime({
            targets: this,
            width: width,
            duration: 400,
            easing: 'easeOutQuad'
          });
    }

    let closeCred = function(){
        this.addEventListener("mouseenter", openCred );
        this.removeEventListener("mouseleave", closeCred );
        anime({
            targets: this,
            width: 25,
            duration: 400,
            easing: 'easeOutQuad'
        });
    }

    initFunc();
  };
}
export { Credit }