import anime from '../libs/anime'
import Hammer from '../libs/hammer'


class M03SliderSimple {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.slider = document.querySelectorAll('.module-03-slider.layout-simple .slider_container');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.slider.length; ++i) {
        const arrowL = cache.slider[i].querySelector('.arrow_left');
        const arrowR = cache.slider[i].querySelector('.arrow_right');
        arrowL.addEventListener("click", slideLeft );
        arrowR.addEventListener("click", slideRight );
        let hammertime = new Hammer(cache.slider[i]);
        hammertime.on('swipe', function(ev) {
            if(ev.direction == 2) {
                arrowL.click();
            }
            if(ev.direction == 4) {
                arrowR.click();
            }
        });
      }
    };

    let slideLeft = function(){
        
        const arrow = this;
        arrow.removeEventListener("click", slideLeft );
        const slider = arrow.closest('.slider_container').querySelector('.slider');
        const slides = slider.querySelectorAll('.slide');
        let index = slider.dataset.index;
        const currentSlide = slides[index];

        if(index > 0) {
            index--;
        } else {
            index = slides.length-1;
        }

        const targetSlide = slides[index];
        slider.dataset.index = index;
        targetSlide.style.display = "block";

        anime({
            targets: currentSlide,
            translateX: ['0%', '-100%'],
            duration: 600,
            easing: 'easeOutQuad',
            complete: function(){
                currentSlide.style.display = "none";
                arrow.addEventListener("click", slideLeft );
            }
        });
        anime({
            targets: targetSlide,
            translateX: ['100%', '0%'],
            duration: 600,
            easing: 'easeOutQuad'
        });

    }

    let slideRight = function(){
        const arrow = this;
        arrow.removeEventListener("click", slideRight );
        const slider = arrow.closest('.slider_container').querySelector('.slider');
        const slides = slider.querySelectorAll('.slide');
        let index = slider.dataset.index;
        const currentSlide = slides[index];

        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }

        const targetSlide = slides[index];
        slider.dataset.index = index;
        targetSlide.style.display = "block";

        anime({
            targets: currentSlide,
            translateX: ['0%', '100%'],
            duration: 600,
            easing: 'easeOutQuad',
            complete: function(){
                currentSlide.style.display = "none";
                arrow.addEventListener("click", slideRight );
            }
        });
        anime({
            targets: targetSlide,
            translateX: ['-100%', '0%'],
            duration: 600,
            easing: 'easeOutQuad'
        });
    }

    initFunc();
  };
}
export { M03SliderSimple }