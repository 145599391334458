import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Nav {
  constructor() {
    let cache = {};
    let scrollPos;
    let initFunc = () => {
      initCache();
      registerEvents();
      if(document.querySelector('.loader')) {
        removeLoader();
      }
    }

    let initCache = () => {
      cache.header = document.querySelector('header');
      cache.navDropDesk = cache.header.querySelector('.nav_desktop-drop');
      cache.menuItemParents = cache.header.querySelectorAll('.nav_desktop .parent-item');
      cache.menuItem = cache.header.querySelectorAll('.nav_desktop a.menu-item');
      cache.navDropCloseBtn = cache.navDropDesk.querySelector('.close');
      cache.navLinks = cache.navDropDesk.querySelectorAll('.links a');

      cache.navmobileBtn = cache.header.querySelector('.nav_burger');
      cache.navDropMobile = cache.header.querySelector('.nav_mobile-drop');
      cache.navMobileClose = cache.header.querySelector('.nav-mobile_close');
      cache.menuItemParentsMobile = cache.navDropMobile.querySelectorAll('.menu-item.parent-item');
    };

    let registerEvents = () => {
      window.addEventListener('scroll', scrollDetect);
      
      for (let i = 0; i < cache.menuItemParents.length; i++) {
        cache.menuItemParents[i].addEventListener("mouseenter", openNavDesk);
      }
      for (let i = 0; i < cache.menuItem.length; i++) {
        cache.menuItem[i].addEventListener("mouseenter", closeNavDesk);
      }
      cache.navDropCloseBtn.addEventListener("click", closeNavDesk);
      for (let i = 0; i < cache.navLinks.length; i++) {
        cache.navLinks[i].addEventListener("mouseenter", changeImg);
      }


      cache.navmobileBtn.addEventListener("click", openNavMobile);
      cache.navMobileClose.addEventListener("click", closeNavMobile);

      for (let i = 0; i < cache.menuItemParentsMobile.length; i++) {
        cache.menuItemParentsMobile[i].addEventListener("click", openSubNavMobile);
      }
    };


    let removeLoader = () => {
        const loader = document.querySelector('.loader');
        if(loader) {
          anime({
            targets: loader,
            delay: 800,
            translateY: ['0%','-100%'],
            duration: 600,
            easing: 'easeInQuad',
            complete: function() {
              loader.parentNode.removeChild(loader);
            }
          });
        }
    }


    let openNavDesk = function() {
      for (let i = 0; i < cache.menuItemParents.length; i++) {
        cache.menuItemParents[i].removeEventListener("mouseenter", openNavDesk);
        cache.menuItemParents[i].addEventListener("mouseenter", changeTabNavDesk);
      }

      cache.header.classList.add('open');

      let tab = cache.navDropDesk.querySelector('.'+this.dataset.sub);
      tab.style.display="block";
      tab.style.opacity="1";
      tab.classList.add('active');
      let tabImg = tab.querySelector('.img_shape');

      cache.navDropDesk.style.display="block";

      anime({
          targets: cache.navDropDesk,
          backgroundColor: ['rgba(255,255,255,0)', 'rgba(255,255,255,1)'],
          height: "70vh",
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            cache.header.addEventListener("mouseleave", closeNavDesk);
          }
        });
      anime({
        targets: tabImg,
        opacity: [0,1],
        delay: 300,
        translateX: [20, 0], 
        duration: 400,
        easing: 'easeOutQuad'
      });
    }

    let changeTabNavDesk = function() {
      let currentTab = cache.navDropDesk.querySelector('.nav-drop.active');
      let tab = cache.navDropDesk.querySelector('.'+this.dataset.sub);
      if(currentTab !== tab) {
        tab.style.display="block";
        currentTab.classList.remove('active');
        tab.classList.add('active');
        anime({
          targets: currentTab,
          opacity: 0,
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            currentTab.style.display="none";
          }
        });
        anime({
          targets: tab,
          opacity: 1,
          duration: 400,
          delay:200,
          easing: 'easeOutQuad'
        });
      }
    }

    let changeImg = function() {
      let parent = this.closest('.nav-drop');
      let images =  parent.querySelectorAll('.img_shape .img');
      let currentImg = parent.querySelector('.img.active');
      let img = images[parseInt(this.dataset.index)];

      
      currentImg.classList.remove('active');
      img.classList.add('active');

      if(currentImg !== img) {
        anime({
          targets: currentImg,
          opacity: 0,
          rotate: 10,
          duration: 400,
          easing: 'easeOutQuad',
        });
        anime({
          targets: img,
          opacity: 1,
          rotate: [-10,0],
          duration: 400,
          easing: 'easeOutQuad'
        });
      }
    }

    let closeNavDesk = function() {
      cache.header.removeEventListener("mouseleave", closeNavDesk);
      if(cache.header.classList.contains('open')) {
        for (let i = 0; i < cache.menuItemParents.length; i++) {
          cache.menuItemParents[i].removeEventListener("mouseenter", changeTabNavDesk);
        }
        setTimeout(function() {
          cache.header.classList.remove('open');
        }, 200);
        
        let tab = cache.navDropDesk.querySelector('.nav-drop.active');
        anime({
            targets: cache.navDropDesk,
            backgroundColor: ['rgba(255,255,255,1)', 'rgba(255,255,255,0)'],
            height: "0vh",
            duration: 400,
            delay:200,
            easing: 'easeOutQuad',
            complete: function() {
              cache.navDropDesk.style.display="none";
              tab.style.display="none";
              tab.classList.remove('active');
              tab.style.opacity="0";
              for (let i = 0; i < cache.menuItemParents.length; i++) {
                cache.menuItemParents[i].addEventListener("mouseenter", openNavDesk);
                cache.menuItemParents[i].removeEventListener("mouseenter", changeTabNavDesk);
              }
            }
          }); 
          let tabImg = tab.querySelector('.img_shape');
          anime({
            targets: tabImg,
            opacity: [1,0],
            translateX: [0, 20], 
            duration: 400,
            easing: 'easeOutQuad'
          });
      }
    }
    


    let openNavMobile = function() {
      cache.navmobileBtn.removeEventListener("click", openNavMobile);
      
      cache.header.classList.add('open');
      cache.navMobileClose.style.display="block";

      cache.navDropMobile.style.display="block";
      cache.navDropMobile.style.height="auto";
      let height = cache.navDropMobile.offsetHeight;
      cache.navDropMobile.style.height=0;

      anime({
          targets: cache.navDropMobile,
          backgroundColor: ['rgba(255,255,255,0)', 'rgba(255,255,255,1)'],
          height:[0, height],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            cache.navDropMobile.style.height="auto";
            cache.navmobileBtn.addEventListener("click", closeNavMobile);
          }
        });
    }

    let closeNavMobile = function() {
      cache.navmobileBtn.removeEventListener("click", closeNavMobile);
      
      cache.header.classList.remove('open');
      cache.navMobileClose.style.display="none";
      let height = cache.navDropMobile.offsetHeight;
      cache.navDropMobile.style.height=height+"px";
      
      anime({
          targets: cache.navDropMobile,
          backgroundColor: ['rgba(255,255,255,1)', 'rgba(255,255,255,0)'],
          height:[height, 0],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            cache.navmobileBtn.addEventListener("click", openNavMobile);
            if(cache.navDropMobile.querySelector('.menu-item.parent-item.open')) {
              let openedDrop = cache.navDropMobile.querySelectorAll('.menu-item.parent-item.open');
              for (let i = 0; i < openedDrop.length; i++) {
                openedDrop[i].click();
              }
            }
          }
        });
    }

    let openSubNavMobile = function() {
      const el = this;
      el.removeEventListener("click", openSubNavMobile);
      el.classList.add('open');
      const drop = el.querySelector('.nav-drop');

      drop.style.display="block";
      drop.style.height="auto";
      let height = drop.offsetHeight;
      drop.style.height=0;

      anime({
          targets: drop,
          height:[0, height],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            el.addEventListener("click", closeSubNavMobile);
          }
        });
    }

    let closeSubNavMobile = function() {
      const el = this;
      el.removeEventListener("click", closeSubNavMobile);
      el.classList.remove('open');
      const drop = el.querySelector('.nav-drop');

      anime({
          targets: drop,
          height:0,
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            drop.style.display="none";
            el.addEventListener("click", openSubNavMobile);
          }
        });
    }




    let scrollDetect = function() {
      let animation = false;
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

      if(scrollPos > 150 && !up && !cache.header.classList.contains('up') ) {
        cache.header.classList.add('up');
      } else if(scrollPos > 150 && up && cache.header.classList.contains('up') ) {
        cache.header.classList.remove('up');
      }

      if(document.querySelector('.module-16-ancres')) {
        let ancres = document.querySelector('.module-16-ancres');
        let marker =  document.querySelector('.module-16-ancres_marker');
        console.log(marker.offsetTop)
        if(scrollPos > marker.offsetTop && !up && !ancres.classList.contains('up') ) {
            ancres.classList.add('up');
        } else if(scrollPos > marker.offsetTop && up && ancres.classList.contains('up') ) {
            ancres.classList.remove('up');
        } else if(scrollPos < marker.offsetTop && !ancres.classList.contains('up') ) {
          ancres.classList.add('up');
        }
      }

    }

    initFunc();
  };
}
export { Nav }
